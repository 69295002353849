<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.subtitle
  font-weight: bold
  font-size: 1rem
  color: #777
.ghost
  opacity: 0.5
  // background: #c8ebfb
  // background-color: #fff !important
.dragging
  .flip-list-move
    transition: transform 0.5s
  .btn
    cursor: ns-resize
    background-color: #fff !important

.toc
  position: fixed
  top: 0px
  right: 0px

.card-link
  text-decoration: none
  &:hover
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1) !important
    // .card-body
    // .card-overlay
    // background: none !important
.prog-active
  font-weight: bold
.prog-prev
  color: #999 !important
.tabs-solid
  .nav-tabs
    .nav-item
      a
        border-radius: 2px !important
      .nav-link
        border-radius: 0px !important
</style>
<template lang="pug">
div
  .d-flex.justify-content-center.align-items-top(style='min-height: 100vh')
    .col-9
      .mb-4
      //- h3.title.py-3 메시지 보내기
        //- .btn-group.float-right
          button.btn.btn-light(type='button' @click='$modal.show(`template-list`)')
            span(v-if='form.message_template_id')
              span(v-if='current_template.form_json') {{current_template.form_json.name}}
              span(v-else) {{current_template.name || '템플릿'}}
              span.text-primary  (선택됨)
            span(v-else) 템플릿 가져오기
          button.btn.btn-light.text-secondary(type='button' @click='reset_template')
            small.mr-1: b-icon-trash
            | 지우기
      div.mb-4
        .bg-light.rounded(style='height: 10px')
          .float-left.rounded(style='width: 20%; height: 10px; background-color: #0068b7' v-show='step == 1')
          .float-left.rounded(style='width: 45%; height: 10px; background-color: #0068b7' v-show='step == 2')
          .float-left.rounded(style='width: 70%; height: 10px; background-color: #0068b7' v-show='step == 3')
          .float-left.rounded(style='width: 90%; height: 10px; background-color: #0068b7' v-show='step == 4')
        .clearfix
          .float-left.text-muted.pt-1(style='width: 25%; height: 10px;' :class="{'prog-active':(step==1), 'prog-prev':(step>1)}") 채널선택
          .float-left.text-muted.pt-1(style='width: 25%; height: 10px;' :class="{'prog-active':(step==2), 'prog-prev':(step>2)}") 대상자
          .float-left.text-muted.pt-1(style='width: 25%; height: 10px;' :class="{'prog-active':(step==3), 'prog-prev':(step>3)}") 내용입력
          .float-left.text-muted.pt-1(style='width: 25%; height: 10px;' :class="{'prog-active':(step==4), 'prog-prev':(step>4)}") 조건 및 트리커
        .clearfix
        //- b-progress(class="mt-2" :max="4")
        //-   b-progress-bar(value="1" variant="success")
      hr
      form.form.async-hidden(:class='{done: (step==1)}')
        .form-group
          label 보낼 메시지 채널을 선택해주세요.
          label.btn.btn-default.bg-white.btn-block.text-left.shadow-sm.rounded.border.card-link
            b-form-checkbox.rounded-0.py-2(v-model='form.has_email' value='Y' unchecked-value='N' style='padding-left: 2.5rem')
              span.d-block(style='font-size: 1.25rem; line-height: 1.4rem') 이메일
          .clearfix
          label.btn.btn-default.bg-white.btn-block.text-left.shadow-sm.rounded.border.card-link
            span.float-right(style='opacity: 0.5') SMS, LMS, MMS
            b-form-checkbox.rounded-0.py-2(v-model='form.has_sms' value='Y' unchecked-value='N' style='padding-left: 2.5rem')
              span.d-block.w-100(style='font-size: 1.25rem; line-height: 1.4rem') 문자
          .clearfix
          label.btn.btn-default.bg-white.btn-block.text-left.shadow-sm.rounded.border.card-link
            span.float-right(style='opacity: 0.5') 비즈톡(알림톡), 친구톡, 친구톡 이미지
            b-form-checkbox.rounded-0.py-2(v-model='form.has_kakao' value='Y' unchecked-value='N' style='padding-left: 2.5rem')
              span.d-block(style='font-size: 1.25rem; line-height: 1.4rem') 카카오톡
          .clearfix
          label.btn.btn-default.bg-white.btn-block.text-left.shadow-sm.rounded.border.card-link
            span.float-right(style='opacity: 0.5') Outgoing webhook, POST API
            b-form-checkbox.rounded-0.py-2(v-model='form.has_webhook' value='Y' unchecked-value='N' style='padding-left: 2.5rem')
              span.d-block(style='font-size: 1.25rem; line-height: 1.4rem') API 전송
          .clearfix
          label.btn.btn-default.bg-white.btn-block.text-left.shadow-sm.rounded.border.card-link
            span.float-right(style='opacity: 0.5') Add to other datasource.
            b-form-checkbox.rounded-0.py-2(v-model='form.has_datasoruce' value='Y' unchecked-value='N' style='padding-left: 2.5rem')
              span.d-block(style='font-size: 1.25rem; line-height: 1.4rem') DB 쿼리 전송
          //- .clearfix
          //- label.btn.btn-default.bg-white.btn-block.text-left.shadow-sm.rounded.border.card-link
          //-   span.float-right(style='opacity: 0.5') CSV, XLS, XLSX
          //-   b-form-checkbox.rounded-0.py-2(v-model='form.has_excel' value='Y' unchecked-value='N' style='padding-left: 2.5rem')
          //-     span.d-block(style='font-size: 1.25rem; line-height: 1.4rem') 엑셀 파일
          .clearfix
        .form-group
          button.btn.btn-primary.px-4(type='button' @click='set_step(2)') 다음
            b-icon-chevron-right.ml-3

      form.form.async-hidden(:class='{done: (step==2)}')
        .form-group
          label 대상자를 확인해주세요.
          label.d-block
            button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-secondary.mr-1(type='button' @click.prevent='' disabled v-for='item in filters')
              b-icon-person-fill(v-if='item.prekey == "search.customer"')
              span(v-if='item.prekey == "search.field"') {{item.col.label}}:&nbsp;
              span(v-if='item.key == "search.field.value_not_contain"') (제외)&nbsp;
              span(v-if='item.key == "search.field.value_is_empty"') (비어있음)&nbsp;
              span(v-if='item.key == "search.field.value_isnt_empty"') (비어있지않음)&nbsp;
              span(v-if='item.key == "search.row.not_in_ids"') (고객아이디 제외)&nbsp;
              span(v-if='item.key == "search.row.in_ids"') (고객아이디 지정)&nbsp;
              span(v-if='item.prekey == "search.row"') {{item.value.length}}건&nbsp;
              span(v-else) {{item.value}}

          //- span.bg-light.p-2 목록: {{document.name}}
          div(v-if='saved_filter.id')
            button.btn.mr-4(type='button' @click='open_audience()' :class='(count_audience == rows_audience.length) ? "btn-secondary" : "btn-light text-primary"') 필터된 고객 ({{document.name}})
            label.mr-4 연락처수: {{document_stat.count_all}}건
              span.text-muted(v-if='rows_audience.length != document_stat.count_total')  (총 {{document_stat.count_total}}건)
            label.mr-4 문자수신허용: {{document_stat.count_phone}}건
            label.mr-4 이메일수신허용: {{document_stat.count_email}}건
            br


          div(v-else)
            button.btn.mr-4(type='button' @click='open_audience()' :class='(count_audience == rows_audience.length) ? "btn-secondary" : "btn-light text-primary"')
              span(v-if='count_audience < rows_audience.length') 일부고객 ({{document.name}})
              span(v-else) 전체고객 ({{document.name}})
            label.mr-4 연락처수: {{document_stat.count_all}}건
            label.mr-4 문자수신허용: {{document_stat.count_phone}}건
            label.mr-4 이메일수신허용: {{document_stat.count_email}}건

          div.mt-2(v-show='show_audience')
            //- pre {{rows_audience}}
            .bg-light.p-4.rounded
              label.mr-3.py-3.text-dark 총 대상자 수: {{count_audience}}건
              .mb-2.float-right
                div(v-if='$refs.table')
                  label.mr-3(v-if='$refs.table.selectedRows.length') {{$refs.table.selectedRows.length}}건 선택됨

                  //- button.btn.mr-2.btn-primary(type='button' @click='set_audience("Y")' :disabled='$refs.table.selectedRows.length == 0') 대상 ON
                  //- button.btn.mr-2.btn-secondary(type='button' @click='set_audience("N")' :disabled='$refs.table.selectedRows.length == 0') 대상 OFF
              .clearfix
              vue-good-table.table-default.bg-white(
                ref='table'
                styleClass='vgt-table striped hover'
                :columns='columns_audience'
                :rows='rows_audience'
                max-height='50vh'
                :fixed-header='true'
                compactMode
                :sort-options='sortOptions'
                :search-options="searchOptions"
                :pagination-options='paginationOptions'
              )
              //- :selectOptions='selectOptions'
          .clearfix
        .form-group
          button.btn.btn-primary.px-4.mr-2(type='button' @click='set_step(3)') 다음
            b-icon-chevron-right.ml-3
          button.btn.btn-light.px-4.mr-2(type='button' @click='set_step(2)') 이전단계로

      form.form.async-hidden(:class='{done: (step==3)}')
        .form-group
          label 내용을 입력해주세요.
          b-tabs.tabs-solid.shadow-sm.border.rounded.bg-white(card)
            b-tab(title="이메일")
              .form-group
                label 제목
                input.form-control(v-model='form.email_subject' ref='email_subject' @change='save_draft')
                .mt-1
                  button.btn.btn-default.border.mr-1.mb-1.shadow-sm.p-2(type='button' @click='insert_col_email_subject(col)' v-for='col in document.config.cols') {{col.label}}
              hr
              .form-group
                label 본문
                .mt-1
                  button.btn.btn-default.border.mr-1.mb-1.shadow-sm.p-2(type='button' @click='insert_col_email_body(col)' v-for='col in document.config.cols') {{col.label}}

                editor(:value='form.email_body' @onBlur='editor_blur' :onImageUpload='editor_onImageUpload' ref='email_body' :editor_options='editor_options')
              hr
              .form-group
                label 본문 미리보기
                b-form-checkbox(v-model='form.email_use_preview_text' value='Y' unchecked-value='N' switch)
                  span(v-show='form.email_use_preview_text == "Y"') 수동으로 입력하기
                  span(v-show='form.email_use_preview_text != "Y"') 자동으로 본문에서 채움
                div(v-show='form.email_use_preview_text == "Y"')
                  textarea.form-control(v-model='form.email_preview_text')
              hr
              .form-group
                label 발신자
                .row(v-if='$store.state.email_config')
                  .col
                    select.form-control(v-model='form.email_sender' title='발신자 지정이 필요합니다.' :class='{"is-invalid":(!form.email_sender)}' required)
                      option(:value='email.id' v-for='(email, $index) in $store.state.email_config.shared') {{email.from_name}} <{{email.username + email.address}}>
                  .col-3.pl-0
                    button.btn.btn-secondary.btn-block(type='button' @click='open_email_config') 발신자 관리
                p.text-primary(v-else)
                  b-icon-exclamation-triangle.mr-2
                  strong 발신자 등록이 필요합니다.
                modal.py-2(name='email-config' width='700px' :height='`auto`' :scrollable='true')
                  button.btn.btn-default.float-right.text-muted.rounded-0(type='button' @click='$modal.hide("email-config")' style='font-size: 1.5rem')
                    b-icon-x
                  .p-2(v-if='property_id')
                    manage-email(:property_id='property_id')
                    .mb-4
                    .mb-4
                  //- tr
                    th.w-25.th.text-right 수신자
                    td
                      select.form-control
                        option(value='고객 이메일 주소' selected) 고객 이메일 주소
            b-tab(title="문자")
            b-tab(title="API 전송")
              .form-group
                label URL
                input.form-control(value='https://hooks.slack.com/services/T01EGA/B01H1/LmdhhxoT7R')
              hr
              .form-group
                label 형식
                select.form-control
                  option JSON
              hr
              .form-group
                label 인증 토큰
                input.form-control(value='b3a983df-d1b1-40c0-b89f-ddca74981c71')
              hr
              .form-group
                label 콜백하여 내용 업데이트 허용
                b-form-checkbox(v-model='form.email_use_preview_text' value='Y' unchecked-value='N' switch)
                  span(v-show='form.email_use_preview_text == "Y"') 허용함
                  span(v-show='form.email_use_preview_text != "Y"') 허용안함
          .clearfix
        .form-group
          button.btn.btn-primary.px-4.mr-2(type='button' @click='set_step(4)') 다음
            b-icon-chevron-right.ml-3
          button.btn.btn-light.px-4.mr-2(type='button' @click='set_step(3)') 이전단계로

      form.form.async-hidden(:class='{done: (step==4)}')
        .form-group
          label 스케쥴
          .alert.bg-light.w-75.mt-1
            span.text-secondary 예약 및 자동발송은
            strong(style='color: #f39c12;')  Standard
            span.text-secondary  플랜 이상 가입이 필요합니다.
            //- span.badge.badge-warning 프리미엄
            //- p.title 프리미엄 기능입니다.
          select.form-control()
            option 즉시 실행
            option 지정 시간에 실행
        .form-group
          label 반복
          select.form-control
            option 변경이 있을때 즉시
            option 15분
            option 1일
            option 3일
            option 7일
        hr
        .form-group
          label 메시지 발송성공 → 태그추가
          input.form-control(value='캠페인_봄_1차')
        .form-group
          label 메시지 오픈 → 태그추가
          input.form-control(value='캠페인_봄_1차_오픈')
        .form-group
          label 링크 클릭 → 태그추가
          input.form-control(value='캠페인_봄_1차_클릭')

        hr
        .form-group
          label 컨버전 트래킹 (Pull API)
          .alert.bg-light.w-75.mt-1 노이텔러에서 발송대상자 정보를 담은 콜백푸시를 주기적으로 서버에 보냅니다.
          b-form-checkbox(v-model='form.email_use_preview_text1' value='Y' unchecked-value='N' switch)
            span(v-show='form.email_use_preview_text == "Y"') 켜기
            span(v-show='form.email_use_preview_text != "Y"') 끄기
          .clearfix
        .form-group
          label 콜백 받을 주소
          input.form-control(value='https://')
        .form-group
          label 컨버전 트래킹 주기
          select.form-control
            option 1시간
            option 12시간
            option 24시간
        .form-group
          label 컨버전 트래킹 마감일시
          select.form-control
            option 7일뒤
            option 14일뒤
            option 1개월뒤
        hr
        .form-group
          label 컨버전 트래킹 (Push API, 직접 이벤트 전송)
          .alert.bg-light.w-75.mt-1 external_id 기준으로 직접 대상자에 태그를 추가/삭제합니다.
          b-form-checkbox(v-model='form.email_use_preview_text2' value='Y' unchecked-value='N' switch)
            span(v-show='form.email_use_preview_text == "Y"') 켜기
            span(v-show='form.email_use_preview_text != "Y"') 끄기
          .clearfix
        .form-group
          label URL
          input.form-control(value='https://app.noitaler.com/v1/incoming-webhooks/GRQJH9G175/HTH1HQZ8CA/RL71LJ8JKH')
        .form-group
          button.btn.btn-primary.px-4.mr-2(type='button' @click='set_step(4)') 다음
            b-icon-chevron-right.ml-3
          button.btn.btn-light.px-4.mr-2(type='button' @click='set_step(3)') 이전단계로


      modal.modal-default.py-2(name='template-list' :height='`auto`' :scrollable='true')
        .content(style='min-height: 50vh')
          .title 템플릿 목록
          .mb-4
          .mb-4
            div(v-for='t in message_templates' :key='t.id')
              label.py-3.btn.btn-default.btn-block.text-left(:class='{"text-primary": (form.message_template_id == t.id)}' @click='apply_template(t, t.id, t.form_json)') {{t.form_json.name}}
                span(v-if='(form.message_template_id == t.id)')  (선택됨)
                span.float-right.text-muted 변경됨 {{t.updated_at || t.created_at}}

      modal.modal-default.py-2(name='choose-pi' :height='`auto`' :scrollable='false' :clickToClose='false')
        button.btn.btn-default.float-right.text-muted.rounded-0(type='button' @click='$modal.hide("choose-pi")' style='font-size: 1.5rem')
          b-icon-x
        .content(style='min-height: 50vh' v-if='document && document.config')
          .title 고객 연락처 선택
          .mb-2
          p 메시지 발송을 위해 연락처 지정이 필요합니다.

          form.form(@submit.prevent='submit_pi')
            .form-group.pt-4(v-show='form.has_sms == "Y"')
              label.d-block 고객 전화번호
              select.form-control(v-model='form_pi.col_phone')
                option(v-for='col in document.config.cols' :value='col.key') {{col.label}}
            .form-group.pt-4(v-show='form.has_email == "Y"')
              label.d-block 고객 이메일주소
              select.form-control(v-model='form_pi.col_email')
                option(v-for='col in document.config.cols' :value='col.key') {{col.label}}
            .form-group.pt-4
              button.btn.btn-light.mr-2(type='button' @click='$modal.hide("choose-pi")') 닫기
              button.btn.btn-primary(type='submit') 완료




      .toc.p-2
      //- div.async(:class='{done: done}' v-if='document.id')

        form.form(@submit.prevent='submit_template()')
          .mb-4
          //- .form-group.shadow.rounded.p-4.mb-4(v-if='form.message_template_id')
            label.d-block 템플릿 번호
              a.float-right(href='#' @click='copy()') 사본 만들기
            input.form-control.border-0(type='text' v-model='form.message_template_id' readonly)
            .mb-4
          .form-group#item-1
            label.d-block 캠페인 제목
            input.form-control(type='text' v-model='form.name' ref='input0' autofocus @change='save_draft')

          .pb-4
          .form-group
            label.d-block
              span.mr-2 발송 대상
              button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-secondary.mr-1(type='button' @click.prevent='' disabled v-for='item in filters')
                b-icon-person-fill(v-if='item.prekey == "search.customer"')
                span(v-if='item.prekey == "search.field"') {{item.col.label}}:&nbsp;
                span(v-if='item.key == "search.field.value_not_contain"') (제외)&nbsp;
                span(v-if='item.key == "search.field.value_is_empty"') (비어있음)&nbsp;
                span(v-if='item.key == "search.field.value_isnt_empty"') (비어있지않음)&nbsp;
                span(v-if='item.key == "search.row.not_in_ids"') (고객아이디 제외)&nbsp;
                span(v-if='item.key == "search.row.in_ids"') (고객아이디 지정)&nbsp;
                span(v-if='item.prekey == "search.row"') {{item.value.length}}건&nbsp;
                span(v-else) {{item.value}}

            //- span.bg-light.p-2 목록: {{document.name}}
            div(v-if='saved_filter.id')
              button.btn.mr-4(type='button' @click='open_audience()' :class='(count_audience == rows_audience.length) ? "btn-primary" : "btn-light text-primary"') 필터된 고객 ({{document.name}})
              label.mr-4 연락처수: {{document_stat.count_all}}건
                span.text-muted(v-if='rows_audience.length != document_stat.count_total')  (총 {{document_stat.count_total}}건)
              label.mr-4 문자수신허용: {{document_stat.count_phone}}건
              label.mr-4 이메일수신허용: {{document_stat.count_email}}건
              br


            div(v-else)
              button.btn.mr-4(type='button' @click='open_audience()' :class='(count_audience == rows_audience.length) ? "btn-primary" : "btn-light text-primary"')
                span(v-if='count_audience < rows_audience.length') 일부고객 ({{document.name}})
                span(v-else) 전체고객 ({{document.name}})
              label.mr-4 연락처수: {{document_stat.count_all}}건
              label.mr-4 문자수신허용: {{document_stat.count_phone}}건
              label.mr-4 이메일수신허용: {{document_stat.count_email}}건

            div(v-show='show_audience')
              //- pre {{rows_audience}}
              .bg-light.p-4.rounded
                label.mr-3.py-3.text-dark 총 대상자 수: {{count_audience}}건
                .mb-2.float-right
                  div(v-if='$refs.table')
                    label.mr-3(v-if='$refs.table.selectedRows.length') {{$refs.table.selectedRows.length}}건 선택됨

                    //- button.btn.mr-2.btn-primary(type='button' @click='set_audience("Y")' :disabled='$refs.table.selectedRows.length == 0') 대상 ON
                    //- button.btn.mr-2.btn-secondary(type='button' @click='set_audience("N")' :disabled='$refs.table.selectedRows.length == 0') 대상 OFF
                .clearfix
                vue-good-table.table-default.bg-white(
                  ref='table'
                  styleClass='vgt-table striped hover'
                  :columns='columns_audience'
                  :rows='rows_audience'
                  max-height='50vh'
                  :fixed-header='true'
                  compactMode
                  :sort-options='sortOptions'
                  :search-options="searchOptions"
                  :pagination-options='paginationOptions'
                )
                //- :selectOptions='selectOptions'

          .pb-4
          .form-group#item-1-1
            label.d-block 발송 방법
            label.btn.btn-light.btn-block.text-left
              b-form-checkbox.rounded-0.py-2(v-model='form.has_email' value='Y' unchecked-value='N' style='padding-left: 2.5rem' @change='save_draft')
                span.d-block(style='font-size: 1.25rem; line-height: 1.4rem') 이메일
            template(v-if='form.has_email == `Y`')
              .bg-light.px-4.pb-4.mb-4(style='margin-top: -10px')
                //- .pt-4
                .form-group(style='padding-left: 2.5rem')
                  //- label.d-block 이메일 내용입력
                  .shadow-sm.border.rounded.bg-white
                    table.table
                      tbody
                        tr
                          th.border-0.rounded.w-25.th.text-right 제목
                          td.border-0.rounded
                            input.form-control(v-model='form.email_subject' ref='email_subject' @change='save_draft')
                            //- span.text-muted 발송시 고객 데이터가 알맞는 치환자 괄호부분에 채워집니다.
                            .mt-1
                              button.btn.btn-default.border.mr-1.mb-1.shadow-sm.p-2(type='button' @click='insert_col_email_subject(col)' v-for='col in document.config.cols') {{col.label}}
                        tr
                          th.w-25.th.text-right 본문
                            br
                            a(href='#' @click.prevent='editor_template(1)') 템플릿
                          td
                            //- textarea.form-control.pt-3(v-model='form.email_body' ref='email_body' rows=20 @change='save_draft')
                            //- span.text-muted 발송시 고객 데이터가 알맞는 치환자 괄호부분에 채워집니다.
                            .mt-1
                              button.btn.btn-default.border.mr-1.mb-1.shadow-sm.p-2(type='button' @click='insert_col_email_body(col)' v-for='col in document.config.cols') {{col.label}}
                        //- tr
                          th.w-25.th.text-right 옵션
                          td
                            //- b-form-checkbox.rounded-0.py-2(v-model='form.email_has_ad' value='Y' unchecked-value='N' style='padding-left: 2.5rem' @change='save_draft')
                              span (광고) 표기하기
                            //- b-form-checkbox.rounded-0.py-2(v-model='form.email_has_webview' value='Y' unchecked-value='N' style='padding-left: 2.5rem' @change='save_draft')
                              span 내용 줄이고, 자세히보기 링크 추가
                            b-form-checkbox.rounded-0.py-2(v-model='form.email_has_response' value='Y' unchecked-value='N' style='padding-left: 2.5rem' @change='save_draft')
                              span 응답 기능 추가
                        tr
                          td.border-0(colspan=2)
                            editor(:value='form.email_body' @onBlur='editor_blur' :onImageUpload='editor_onImageUpload' ref='email_body' :editor_options='editor_options')
                        tr
                          th.w-25.th.text-right 본문 미리보기
                          td
                            b-form-checkbox(v-model='form.email_use_preview_text' value='Y' unchecked-value='N' switch)
                              span(v-show='form.email_use_preview_text == "Y"') 수동으로 입력하기
                              span(v-show='form.email_use_preview_text != "Y"') 자동으로 본문에서 채움
                            div(v-show='form.email_use_preview_text == "Y"')
                              textarea.form-control(v-model='form.email_preview_text')
                        tr
                          th.w-25.th.text-right 발신자
                          td
                            //- pre {{$store.state.email_config}}
                            //- pre {{form.email_sender}}
                            .row(v-if='$store.state.email_config')
                              .col
                                select.form-control(v-model='form.email_sender' title='발신자 지정이 필요합니다.' :class='{"is-invalid":(!form.email_sender)}' required)
                                  option(:value='email.id' v-for='(email, $index) in $store.state.email_config.shared') {{email.from_name}} <{{email.username + email.address}}>
                              .col-3.pl-0
                                button.btn.btn-secondary.btn-block(type='button' @click='open_email_config') 발신자 관리
                            p.text-primary(v-else)
                              b-icon-exclamation-triangle.mr-2
                              strong 발신자 등록이 필요합니다.
                            modal.py-2(name='email-config' width='700px' :height='`auto`' :scrollable='true')
                              button.btn.btn-default.float-right.text-muted.rounded-0(type='button' @click='$modal.hide("email-config")' style='font-size: 1.5rem')
                                b-icon-x
                              .p-2(v-if='property_id')
                                manage-email(:property_id='property_id')
                                .mb-4
                                .mb-4
                        //- tr
                          th.w-25.th.text-right 수신자
                          td
                            select.form-control
                              option(value='고객 이메일 주소' selected) 고객 이메일 주소

            //- label.btn.btn-light.btn-block.text-left
              b-form-checkbox.rounded-0.py-2(v-model='form.has_kakao' value='Y' unchecked-value='N' style='padding-left: 2.5rem' @change='save_draft')
                span.d-block(style='font-size: 1.25rem; line-height: 1.4rem') 카카오 알림톡

            //- template(v-if='form.has_kakao == `Y`')
              .bg-light.px-4.pb-4.mb-4(style='margin-top: -10px')
                //- .pt-4
                .form-group(style='padding-left: 2.5rem')
                  //- label.d-block 이메일 내용입력
                  .shadow-sm.border.rounded.bg-white
                    table.table
                      tbody
                        tr
                          th.border-0.rounded.w-25.th.text-right 템플릿
                          td.border-0.rounded
                            select.form-control(v-model='form.kakao_template_id')
                              option(value='ID1') 1번
                            .rounded.mt-2.p-4(style='background-color: #c6d6e3')
                              .mb-4.bg-white.shadow-sm.p-2.rounded(style="font-size: 0.8rem; width: 18rem; color: #333; font-weight: 500; white-space: pre-line") {{current_kakao_template.body}}
                        tr
                          th.border-0.rounded.w-25.th.text-right 제목
                          td.border-0.rounded
                            input.form-control(v-model='form.kakao_subject' @change='save_draft')
                        tr
                          th.border-0.rounded.w-25.th.text-right 내용
                          td.border-0.rounded
                            div(v-for='key in current_kakao_template.keys')
                              label {{key}}
                              .row
                                .col
                                  input.form-control(v-model='form.kakao_body[key]' @change='save_draft')
                                .col
                                  select.form-control(v-model='form.kakao_body[key]')
                                    option(:value='`{{${col.label}}}`' v-for='col in document.config.cols') {{col.label}}
                        tr
                          th.border-0.rounded.w-25.th.text-right 옵션
                          td.border-0.rounded
                            b-form-checkbox.rounded-0.py-2(v-model='form.kakao_has_fallback' value='Y' unchecked-value='N' style='padding-left: 2.5rem' @change='save_draft')
                              span 대체 발송 허용

            label.btn.btn-light.btn-block.text-left
              b-form-checkbox.rounded-0.py-2(v-model='form.has_sms' value='Y' unchecked-value='N' style='padding-left: 2.5rem' @change='save_draft')
                span.d-block(style='font-size: 1.25rem; line-height: 1.4rem') 문자

            template(v-if='form.has_sms == `Y`')
              .bg-light.px-4.pb-4.mb-4(style='margin-top: -10px')
                //- .pt-4
                .form-group(style='padding-left: 2.5rem')
                  //- label.d-block 이메일 내용입력
                  .shadow-sm.border.rounded.bg-white
                    table.table
                      tbody
                        //- tr
                          th.border-0.rounded.w-25.th.text-right 제목
                          td.border-0.rounded
                            input.form-control(v-model='form.sms_subject' @change='save_draft')
                        tr
                          th.border-0.w-25.th.text-right 본문
                          td.border-0
                            textarea.form-control.pt-3(v-model='form.sms_body' ref='sms_body' rows=10 @change='save_draft' style='width: 15rem')
                            .mt-1
                              button.btn.btn-default.border.mr-1.mb-1.shadow-sm.p-2(type='button' @click='insert_col_sms_body(col)' v-for='col in document.config.cols') {{col.label}}
                        //- tr
                          th.w-25.th.text-right 옵션
                          td
                            //- b-form-checkbox.rounded-0.py-2(v-model='form.sms_has_ad' value='Y' unchecked-value='N' style='padding-left: 2.5rem' @change='save_draft')
                              span (광고) 표기하기
                            b-form-checkbox.rounded-0.py-2(v-model='form.sms_has_webview' value='Y' unchecked-value='N' style='padding-left: 2.5rem' @change='save_draft')
                              span 내용 줄이고, 자세히보기 링크 추가
                            b-form-checkbox.rounded-0.py-2(v-model='form.sms_has_response' value='Y' unchecked-value='N' style='padding-left: 2.5rem' @change='save_draft')
                              span 응답 기능 추가
                        tr
                          th.w-25.th.text-right 발신자
                          td
                            .row(v-if='property.sms_config && property.sms_config.numbers.length > 0 && property.sms_config.numbers[0].number')
                              .col
                                select.form-control(v-model='form.sms_sender' title='발신자 지정이 필요합니다.' :class='{"is-invalid":(!form.sms_sender)}'  required)
                                  option(:value='sms.number' v-for='(sms, $index) in property.sms_config.numbers') {{sms.number}}
                              .col-3.pl-0
                                button.btn.btn-secondary.btn-block(type='button' @click='open_sms_config') 발신자 관리

                            p.text-primary(v-else)
                              b-icon-exclamation-triangle.mr-2
                              strong 발신자 등록이 필요합니다.
                            modal.py-2(name='sms-config' width='580px' :height='`auto`' :scrollable='true' @before-close='before_close_sms_config')
                              button.btn.btn-default.float-right.text-muted.rounded-0(type='button' @click='$modal.hide("sms-config")' style='font-size: 1.5rem')
                                b-icon-x
                              .p-2(v-if='property_id')
                                manage-sms(:property_id='property_id')
                                .mb-4
                                .mb-4
            //- b-form-checkbox.rounded-0.py-2(v-model='form.has_kakao' value='Y' unchecked-value='N' style='padding-left: 2.5rem' @change='save_draft')
            //-   span 카카오 알림톡
            //- b-form-checkbox.rounded-0.py-2(v-model='form.has_sms' value='Y' unchecked-value='N' style='padding-left: 2.5rem' @change='save_draft')
            //-   span 문자



          .pt-4
          .pt-4
          //- .form-group(style='opacity: .5')
            label.d-block 미리보기
            select.form-control.mb-2(disabled)
              option 하박사 dr.ha@studiointo.asia 010-2222-2222
            b-card(no-body)
              b-tabs(card )
                b-tab(title='이메일')
                b-tab(title='카카오 알림톡')
                b-tab(title='문자')
                //- template(v-if='form.has_email == `Y`')
            .mt-2
              button.btn.btn-light.text-primary(type='button' @click='') 나에게 테스트 발송하기



          .clearfix
          .mb-4
          .form-group
            //- pre {{form}}
            //- button.btn.btn-default.text-danger.float-left(type='button' @click='remove()') 삭제
            button.btn.btn-primary.float-right(type='button' @click='submit_view()' :disabled='saving || !processable') {{saved_view_text}}
            //- button.btn.btn-light.text-primary.float-right.mr-2(type='submit' :disabled='saving') {{saved_text}}
            button.btn.btn-light.text-primary.float-right.mr-2(type='submit' :disabled='saving') {{saved_text}}
            //- router-link.btn.btn-light.text-secondary.float-right.mr-2(:to='`/property/${property_id}/customer/${document_id}`') 뒤로가기
          .clearfix
          .pb-4


</template>

<script>

import CustomerTableConfig from '@/components/CustomerTableConfig'
import Editor from '@/components/Editor'
import ManageEmail from '@/views/ManageEmail'
import ManageSMS from '@/views/ManageSMS'

const $ = require('jquery')

const VERSION = 2

const FORM_BLANK = {
  name: '',
  has_kakao: 'N',
  has_email: 'N',
  has_sms: 'N',
  email_subject: '',
  email_body: '',
  email_use_preview_text: 'N',
  email_preview_text: '',
  sms_body: '',
  kakao_subject: '',
  kakao_body: {},
}

export default {
  name: 'index',
  props: ['property_id', 'document_id', 'saved_filter_id'],
  components: {
    CustomerTableConfig,
    ManageEmail,
    'manage-sms': ManageSMS,
    Editor,
  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
    property() {
      return this.$store.state.property
    },
    processable() {
      return this.form.has_email == 'Y' || this.form.has_kakao == 'Y' || this.form.has_sms == 'Y'
    },
  },
  watch: {
    '$store.state.documents'() {
      this.load()
    },
    'form'() {
      this.save_draft()
    },
  },
  data() {
    return {
      step: 1,
      // step: 4,

      done: false,
      saving: false,
      saved_text: '템플릿으로 저장하기',
      saved_view_text: '발송 시작하기',
      document: {},
      document_stat: {},
      error: '',

      document_count: null,

      message_templates: [],

      form: FORM_BLANK,

      current_kakao_template: {
        keys: ['주문번호', '제품명'],
        body: `"{{제품명}}" 새로운 소식을 알려드립니다.\n주문번호: {{주문번호}}\n아래 버튼을 눌러 자세히 보기`,
      },

      // last_col_id: 10,

      cols: [

      ],

      should_save_cols: false,

      show_audience: false,
      rows_audience: [],
      count_audience: 0,
      columns_audience: [
        {
          label: '고객아이디',
          field: 'external_id',
        },
        {
          label: '이름',
          field: 'name',
        },
        {
          label: '전화번호',
          field: 'phone',
        },
        {
          label: '이메일',
          field: 'email',
        },
        {
          label: '대상',
          field: 'target',
          tdClass: (row) => {
            return row.target == 'Y' ? 'table-success text-success font-weight-bold' : 'text-muted '
          },
        },
      ],
      selectOptions: {
        enabled: true,
        // selectOnCheckboxOnly: true,
        // selectionInfoClass: 'custom-class',
        // selectionText: 'rows selected',
        // clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      searchOptions: {
        enabled: true,
      },
      paginationOptions: {
        enabled: true,
        mode: 'pages',
        perPage: 100,
        position: 'bottom',
        setCurrentPage: 1,
        perPageDropdown: [10, 30, 100, 1000],
        dropdownAllowAll: true,
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [
          // {
          //   field: 'id',
          //   type: 'desc',
          // },
        ]
      },

      editor_options: {
        ref: null,
        value: '',
      },

      saved_filter: {},
      filters: [],
      current_filter_id: 0,
      current_template: {},

      col_phone: {},
      col_email: {},
      form_pi: {
        col_phone: '',
        col_email: '',
      }
    }
  },
  async mounted() {
    this.$store.dispatch('email config', this.property_id)
    // this.loaded = true
    // this.load()
    if (this.$store.state.documents) {
      this.load()
    }
    // this.load()
    // this.add_col()
    // this.open_sms_config()

    // this.open_audience()
    setTimeout(() => {
      // $('#editor').summernote()

    }, 300);
  },
  methods: {
    set_step(step) {
      this.step = step
      if (this.step == 2) {
        this.show_audience = false
        this.open_audience()
      }
    },
    set_message_type() {

    },
    async submit_pi() {
      try {
        const new_cols = [].concat(this.document.config.cols).map(e => {
          if (this.form_pi.col_phone == e.key) {
            e.format = '@고객연락처'
          }
          if (this.form_pi.col_email == e.key) {
            e.format = '@고객이메일'
          }
          return e
        })

        const document = Object.assign({}, this.document, {
          config: Object.assign({}, this.document.config, {
            cols: new_cols,
          })
        })
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/documents/${this.document_id}`, document)
        if (r?.data?.message != 'ok') throw new Error('연락처항목 저장 실패')

        this.col_phone = new_cols.filter(e => e.format == '@고객연락처')[0] || {}
        this.col_email = new_cols.filter(e => e.format == '@고객이메일')[0] || {}

        this.$modal.show('dialog', {
          title: '알림',
          text: '저장했습니다.',
        })
        this.$modal.hide('choose-pi')
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    editor_template() {
      if (this.form.email_body.trim().length) {
        if (!confirm('입력중인 내용이 사라집니다. 계속하시겠습니까?')) return false
      }
      // this.form.email_body = `<p style=\"color: rgb(34, 34, 34); font-size: 14px; white-space: pre-line; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; padding: 25px;\"><img style=\"width: 25%;\" src=\"https://noitaler-sandbox.s3.ap-northeast-2.amazonaws.com/logo.png\"></p><p style=\"color: rgb(34, 34, 34); font-size: 28px; margin-right: 0px; margin-bottom: 15px; margin-left: 0px; white-space: pre-line; line-height: 38px; letter-spacing: -1px; padding: 0px 25px;\">제목</p><p style=\"font-size: 14px; margin-right: 0px; margin-bottom: 15px; margin-left: 0px; white-space: pre-line; color: rgb(85, 85, 85); line-height: 22px; letter-spacing: -1px; padding: 0px 25px;\">내용을 입력합니다.</p>`
      this.editor_options.$ref.summernote('reset')
      this.editor_options.$ref.summernote('pasteHTML', `<p style=\"color: rgb(34, 34, 34); font-size: 14px; white-space: pre-line; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; padding: 25px;\"><img style=\"width: 25%;\" src=\"https://noitaler-sandbox.s3.ap-northeast-2.amazonaws.com/logo.png\"></p><p style=\"color: rgb(34, 34, 34); font-size: 28px; margin-right: 0px; margin-bottom: 15px; margin-left: 0px; white-space: pre-line; line-height: 38px; letter-spacing: -1px; padding: 0px 25px;\">제목</p><p style=\"font-size: 14px; margin-right: 0px; margin-bottom: 15px; margin-left: 0px; white-space: pre-line; color: rgb(85, 85, 85); line-height: 22px; letter-spacing: -1px; padding: 0px 25px;\">내용을 입력합니다.</p>`)
      // this.editor_options.value = `<p style=\"color: rgb(34, 34, 34); font-size: 14px; white-space: pre-line; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; padding: 25px;\"><img style=\"width: 25%;\" src=\"https://noitaler-sandbox.s3.ap-northeast-2.amazonaws.com/logo.png\"></p><p style=\"color: rgb(34, 34, 34); font-size: 28px; margin-right: 0px; margin-bottom: 15px; margin-left: 0px; white-space: pre-line; line-height: 38px; letter-spacing: -1px; padding: 0px 25px;\">제목</p><p style=\"font-size: 14px; margin-right: 0px; margin-bottom: 15px; margin-left: 0px; white-space: pre-line; color: rgb(85, 85, 85); line-height: 22px; letter-spacing: -1px; padding: 0px 25px;\">내용을 입력합니다.</p>`
    },
    editor_onImageUpload(files, editor, editable) {
      console.log('editor_onImageUpload disabled')
      this.$modal.show('dialog', {
        title: '알림',
        text: '메일 작성시 이미지 업로드를 지원하지 않습니다. <br />외부 이미지 링크만 넣도록 되어있습니다.',
      })
      return
    },
    editor_blur(opt) {
      this.form.email_body = opt.code
      if (this.form.email_use_preview_text == 'Y') {
        this.save_draft()
      } else {
        setTimeout(() => {
          this.form.email_preview_text = $(this.editor_options.$ref).children().map((i, e) => { return $(e).text()}).toArray().join(' ')
          this.save_draft()
        }, 300);
      }
    },
    set_audience(v) {
      const ids = this.$refs.table.selectedRows.map(e => e.id)
      // console.log(ids)
      this.rows_audience.forEach((e, i) => {
        if (ids.includes(e.id)) {
          // this.rows_audience[i].target = v
          e.target = v
        }
      })
      this.$refs.table.selectedRows.forEach(e => {
        // e.target = v
        e.vgtSelected = false
      })
      this.count_audience = this.rows_audience.filter(e => e.target == 'Y').length
    },
    async open_audience() {
      try {
        if (this.show_audience) {
          this.show_audience = false
          return
        }
        this.show_audience = true

        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/test-audiences`, {
          params: {
            filters: JSON.stringify(this.filters),
          }
        })
        if (r?.data?.message != 'ok') throw new Error('대상자 불러오기 실패. ' + r.data.message)
        this.rows_audience = r.data.rows.map(e => {
          // if (e.phone || e.email) {
          //   e.target = 'Y'
          // } else {
          //   e.target = 'N'
          // }
          e.target = 'Y'
          return e
        })
        this.count_audience = this.rows_audience.length
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    before_close_sms_config() {
      this.$store.dispatch('property', this.property_id)
    },
    open_email_config() {
      this.$modal.show('email-config')
    },
    open_sms_config() {
      this.$modal.show('sms-config')
    },
    async copy() {
      this.form.message_template_id = undefined
      await this.submit_template()
      this.save_draft()
    },
    reset_template() {
      if(!confirm('내용을 모두 지울까요? *임시저장된 내용도 사라집니다.')) return false
      this.current_template = {}
      this.form.message_template_id = undefined
      this.form = Object.assign({}, FORM_BLANK)
      this.save_draft()

      this.$modal.show('dialog', {
        title: '알림',
        text: '완료',
      })
    },
    apply_template(template, message_template_id, form) {
      if(!confirm('템플릿 내용을 가져올까요? *저장하지 않은 작성중인 내용은 사라집니다.')) return false
      this.form = Object.assign({}, form)
      this.form.message_template_id = message_template_id
      this.current_template = template
      this.$modal.hide('template-list')
    },
    insert_col_email_subject(col) {
      const pos = $(this.$refs.email_subject).prop('selectionStart')
      const b = this.form.email_subject.substring(0, pos)
      const a = this.form.email_subject.substring(pos, this.form.email_subject.length)
      const p = `{{${col.label}}}`
      this.form.email_subject = b + p + a
      this.$nextTick(() => {
        this.save_draft()
        this.$refs.email_subject.focus()
        this.$refs.email_subject.setSelectionRange(pos+p.length, pos+p.length)
      })
    },
    insert_col_email_body(col) {
      if (this.editor_options.$ref) {
        // this.$refs.editor.invoke('insertText', `{{${col.label}}}`)
        this.editor_options.$ref.summernote('insertText', `{{${col.label}}}`)
        return
      }
      // const pos = $(this.$refs.email_body).prop('selectionStart')
      // const b = this.form.email_body.substring(0, pos)
      // const a = this.form.email_body.substring(pos, this.form.email_body.length)
      // const p = `{{${col.label}}}`
      // this.form.email_body = b + p + a
      // this.$nextTick(() => {
      //   console.log(this.form.email_body)
      //   this.save_draft()
      //   // this.$refs.email_body.focus()
      //   // this.$refs.email_body.setSelectionRange(pos, pos+p.length)
      // })
    },
    insert_col_sms_body(col) {
      const pos = $(this.$refs.sms_body).prop('selectionStart')
      const b = this.form.sms_body.substring(0, pos)
      const a = this.form.sms_body.substring(pos, this.form.sms_body.length)
      const p = `{{${col.label}}}`
      this.form.sms_body = b + p + a
      this.$nextTick(() => {
        console.log(this.form.sms_body)
        this.save_draft()
        this.$refs.sms_body.focus()
        this.$refs.sms_body.setSelectionRange(pos+p.length, pos+p.length)
      })
    },
    add_col() {
      this.cols.push({
        key: this.last_col_id++,
        label: '항목이름',
        format: 'text',
        visible_at_table: 'N',
        editable: true,
      })
    },
    save_draft() {
      this.$nextTick(() => {
        window.localStorage.OUTREACH_FORM = JSON.stringify(Object.assign({v:VERSION}, this.form))
        console.log(window.localStorage.OUTREACH_FORM)
      })
    },
    load_draft() {
      if (window.localStorage.OUTREACH_FORM) {
        try {
          const f = JSON.parse(window.localStorage.OUTREACH_FORM)
          if (+f.v < VERSION) return
          this.form = f
          if (this.form.message_template_id) {
            this.current_template = this.message_templates.filter(e => e.id == this.form.message_template_id)[0] || {}
          }

          // if (this.property.email_config?.emails[0]) {
          //   this.form.email_sender = this.property.email_config.emails[0].address
          // }
          // if (this.property.sms_config?.numbers[0]) {
          //   this.form.sms_sender = this.property.sms_config.numbers[0].number
          // }
        } catch (error) {
          console.log('failed to load draft: ', error)
        }
      }
    },
    del_col(col) {
      this.cols = this.cols.filter(e => {
        return e.key != col.key
      })
    },
    async load() {
      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.error = '해당 목록을 찾지 못했습니다.'
        return
      }

      if (!this.document.config) {
        this.document.config = {}
      }
      if (!this.document.config.cols) {
        this.document.config.cols = []
      }
      // if (this.document.config.last_col_id) {
      //   this.last_col_id = this.document.config.last_col_id
      // }
      // const prev_cols = this.cols.slice()
      // const prev_cols_by_key = {}
      // for (const col of prev_cols) {
      //   prev_cols_by_key[col.key] = col
      // }
      // console.log('>>', prev_cols_by_key)
      // this.cols = this.document.config.cols.slice()

      try {
        this.current_filter_id = this.$route.query.fid
        const filter_id = this.current_filter_id || this.saved_filter_id
        if (filter_id) {
          // not loaded, should load
          if (!this.saved_filter.id) {
            // http://localhost:8080/property/3/customer/1-23/이루리-멤버십
            const r2 = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/saved-filters/${filter_id}`)
            if (r2?.data?.message != 'ok' || !r2?.data?.row?.id) {
              this.$modal.show('dialog', {
                title: '알림',
                text: '필터2 가져오지 못했습니다.',
              })
            } else {
              this.filters = r2.data.row.search_config

              if (this.saved_filter_id) {
                const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/saved-filters/${this.saved_filter_id}`)
                if (r?.data?.message != 'ok' || !r?.data?.row?.id) {
                  this.$modal.show('dialog', {
                    title: '알림',
                    text: '필터1 가져오지 못했습니다.',
                  })
                } else {
                  this.saved_filter = r.data.row
                  this.$emit('filter_updated', this.saved_filter)
                }
              }
            }
          }
        }

        this.col_phone = this.document.config.cols.filter(e => e.format == '@고객연락처')[0] || {}
        // if (!this.col_phone.key) {
        //   this.$modal.show('choose-pi')
        //   return
        // }
        this.col_email = this.document.config.cols.filter(e => e.format == '@고객이메일')[0] || {}
        // if (!this.col_email.key) {
        //   this.$modal.show('choose-pi')
        //   return
        // }
        // if (this.col_phone.key && this.col_email.key) {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/count-audiences`, {
          params: {
            filters: JSON.stringify(this.filters),
          }
        })
        if (r?.data?.message != 'ok') throw new Error('고객목록 통계 가져오기 오류. ' + r.data.message)
        this.document_stat = r.data
        // }

        {
          const r = await this.$http.get(`/v1/property/${this.property_id}/views/messages/templates`)
          if (r?.data?.message != 'ok') throw new Error('메시지 템플릿 가져오기 오류. ' + r.data.message)
          this.message_templates = r.data.rows
        }

        this.load_draft()
        // this.open_email_config()
        this.done = true

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async submit_template() {
      try {
        this.saving = true

        this.saved_text = '템플릿으로 저장 중'

        // console.log('>>', row)
        if (this.form.message_template_id) {
          const r = await this.$http.put(`/v1/property/${this.property_id}/views/messages/templates/${this.form.message_template_id}`, {
            form_json: this.form,
          })
          if (r?.data?.message != 'ok') throw new Error('메시지 템플릿 저장 실패. ' + r.data.message)
        } else {
          const r = await this.$http.post(`/v1/property/${this.property_id}/views/messages/templates`, {
            form_json: this.form,
          })
          if (r?.data?.message != 'ok') throw new Error('메시지 템플릿 저장 실패. ' + r.data.message)

          this.form.message_template_id = r.data.id
          this.current_template = Object.assign({}, this.form)
        }

        // await this.$store.dispatch('documents', this.property_id)

        this.saved_text = '템플릿으로 저장됨'
        // this.load()
        {
          const r = await this.$http.get(`/v1/property/${this.property_id}/views/messages/templates`)
          if (r?.data?.message != 'ok') throw new Error('메시지 템플릿 가져오기 오류. ' + r.data.message)
          this.message_templates = r.data.rows
        }

        this.$modal.show('dialog', {
          title: '알림',
          text: '템플릿 저장 완료',
        })

        // this.$router.push({
        //   path: `/property/${this.property_id}/customer/${this.document_id}`,
        // })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
        this.saved_text = '템플릿으로 저장하기'
      }
      setTimeout(() => {
        this.saving = false
      }, 300);
    },
    async submit_view() {
      try {
        if (this.form.has_sms == 'Y') {
          if (!this.form.sms_sender) {
            throw new Error('문자 발신자를 선택해주세요.')
          }
          if (!this.col_phone.key) {
            this.$modal.show('choose-pi')
            return
          }
        }
        if (this.form.has_email == 'Y') {
          if (!this.form.email_sender) {
            throw new Error('이메일 발신자를 선택해주세요.')
          }
          if (!this.col_email.key) {
            this.$modal.show('choose-pi')
            return
          }
        }

        const exclude_ids = this.rows_audience.filter(e => e.target == 'N').map(e => e.id)

        if (!confirm(`${this.document_stat.count_all - exclude_ids.length}건 메시지를 발송하시겠습니까?`)) return false

        this.saving = true

        this.saved_view_text = '저장 중'

        const filters = [].concat(this.filters)

        if (exclude_ids.length) {
          filters.push({
            ts: Date.now(),
            key: 'search.row.not_in_ids',
            value: exclude_ids,
            prekey: 'search.row',
          })
        }

        const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/messages`, {
          ...this.form,
          document_id: this.document_id,
          filters: JSON.stringify(filters),
          saved_filter: this.saved_filter,
          current_filter_id: this.current_filter_id,
        })
        if (r?.data?.message != 'ok') throw new Error('발송요청 실패. ' + r.data.message)

        this.saved_view_text = '저장됨'

        this.$modal.show('dialog', {
          title: '알림',
          text: '발송 요청했습니다.'
        })

        window.localStorage.OUTREACH_FORM = ''

        this.$router.push({
          path: `/property/${this.property_id}/message/${r.data.message_id}/audience`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
        this.saved_view_text = '발송 시작하기'
      }
      setTimeout(() => {
        this.saving = false
      }, 300);
    },
  },
}
</script>
